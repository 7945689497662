import * as React from 'react';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from '@fluentui/react/lib/DetailsList';
import {DocumentStores, DocumentStoreQuota} from '../../interfaces/documentStore';
import { IconTooltip } from '../../utilities/helpers';
import { CommandBarButton, DetailsRow, Dialog, DialogFooter, DialogType, IDetailsListProps, IDetailsRowStyles, IListProps, PrimaryButton, Stack, TextField } from '@fluentui/react';
import NewDocumentStoreModal from './NewDocumentStoreModal';
import RenderDropStats from './RenderDropStats';
import { formatBytes } from '../../utilities/helpers';
import {deleteDocumentStoreByStoreId} from '../../utilities/axiosUtility';
import { throws } from 'assert';
import { reduceEachTrailingCommentRange } from 'typescript';
import { profile } from 'console';
import { toast } from 'react-toastify';
import { UserPermissionLevels } from '../../interfaces/customerAdministrator';


export interface IDocumentStoreTableState {
    columns: IColumn[];
    items:  DocumentStoreQuota[];
    allItems: DocumentStoreQuota[];
    editStoreModalOpen: boolean;
    newStoreModalOpen: boolean;
    selectedEditItem: DocumentStoreQuota|undefined;
    selectedRow: number;
    deleteDialogOpen: boolean;
    selectedDeleteStoreId: number;
}

export interface IDocumentStoreItem {
    key: string;
    name: string;
    description: string
    createdDate: string;
    quota: string;
    remaining: string;
}

export interface IDocumentStoreTableProps {
    currentUserLevels: UserPermissionLevels | undefined
    selectedCustomerId: number|undefined
    documentStores: DocumentStoreQuota[];
    enableEdit: any;
    updateDocumentStoreList: any
}

const controlStyles = {
  root: {
    margin: '0 30px 20px 0',
    maxWidth: '300px',
  },
};

const convertDateFromString = (date: string) => {
  let convertedDate = new Date(date);
  let formatted_date =   convertedDate.toUTCString()
  return formatted_date
}

export default class DocumentStoreTable extends React.Component<IDocumentStoreTableProps, IDocumentStoreTableState>{
    
constructor(props: {currentUserLevels: UserPermissionLevels, updateDocumentStoreList: any, selectedCustomerId: number, documentStores: DocumentStoreQuota[], enableEdit: any}) {
    super(props);

    const columns: IColumn[] = [
        {
            key: 'column1',
            name: 'Name',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: this._onColumnClick,
            // onColumnClick: this._onColumnClick,
            data: 'string',
            isPadded: true,
          },
          {
            key: 'column2',
            name: 'Description',
            fieldName: 'description',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            // onColumnClick: this._onColumnClick,
            data: 'string',
            isPadded: true,
          },
          {
            key: 'column3',
            name: 'Created Date',
            fieldName: 'createdDate',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: this._onColumnClick,
            onRender: (item: DocumentStoreQuota) => {return <div>{convertDateFromString(item.createdDate)}</div>},
            // onColumnClick: this._onColumnClick,
            data: 'string',
            isPadded: true,
          },
          {
            key: 'column4',
            name: 'Quota',
            fieldName: 'maxStorageSize',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: this._onColumnClick,
            onRender: (item: DocumentStoreQuota) => {return <div>{formatBytes(item.maxStorageSize)}</div>},
            // onColumnClick: this._onColumnClick,
            data: 'string',
            isPadded: true,
          },
          {
            key: 'column5',
            name: 'Remaining',
            fieldName: 'remainingStorageSize',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isSorted: false,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: this._onColumnClick,
            onRender: (item: DocumentStoreQuota) => {return <div>{formatBytes(item.remainingStorageSize)}</div>},
            // onColumnClick: this._onColumnClick,
            data: 'string',
            isPadded: true,
          },
          {
            key: 'column6',
            name: 'Action',
            fieldName: 'action',
            minWidth: 100,
            maxWidth: 100,
            isRowHeader: true,
            isResizable: false,
            onRender: (item) => this.renderActionButtons(item),
          },
    ]

    this.state = {
        items: [],
        allItems: [],
        columns: columns,
        editStoreModalOpen: false,
        newStoreModalOpen: false,
        selectedEditItem:  undefined,
        selectedRow: 0,
        deleteDialogOpen: false,
        selectedDeleteStoreId: 0,
      };
}


public componentDidMount(){
  this.setState({items: this.props.documentStores, allItems: this.props.documentStores})
}

public componentDidUpdate(prevProps: IDocumentStoreTableProps){
    if(prevProps.documentStores !== this.props.documentStores){
      this.setState({items: this.props.documentStores, allItems: this.props.documentStores})
    }
}

private canUseActions = () => {
    let canUse = true;
      if(this.props.currentUserLevels === 8)
      {
        canUse = false;
      }
    return canUse;
}


private dismissNewStoreModal = () => {
    this.setState({newStoreModalOpen: !this.state.newStoreModalOpen})
}

private renderActionButtons = (item: DocumentStoreQuota) => {
    if(this.canUseActions())
        return (
          <Stack styles={{root: {cursor: 'pointer'}}} data-selection-disabled={true} horizontal > 
            <IconTooltip onClick={() => this.props.enableEdit(item)} size={20} iconName="Edit" tooltipContent="Edit Store"/>
            <div style={{paddingLeft: 10}}></div>
            {!item.systemGenerated && (<IconTooltip onClick={(e: any) => this.enableDeletePrompt(e, item.id)} size={20} iconName="Delete" tooltipContent="Delete"/>)}
          </Stack>
        )
  }

private enableDeletePrompt = (event: any,  storeId: number) => {
  event.stopPropagation()
  this.setState({selectedDeleteStoreId: storeId, deleteDialogOpen: true})
}

private deleteDocumentStore = async() => {
  if(this.props.selectedCustomerId){
    try{
      let deleteStoreReponse = await deleteDocumentStoreByStoreId(this.props.selectedCustomerId, this.state.selectedDeleteStoreId)
      this.toggleDeleteDialog()
      this.props.updateDocumentStoreList()
      toast.success('Deleted Document Store', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
    
      }
      catch {
      toast.error('Error Deleting Document Store', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        });
      }
    }
}


private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
  if (props) {
    // const customStyles: Partial<IDetailsRowStyles> = {root:{ borderBottom: isSelectedItem? "" : "1px solid rgb(237, 235, 233)"}};
    return (<Stack>
              <RenderDropStats customerId={props.item.customerId} storeId={props.item.id} columns={this.state.columns}>
              <DetailsRow styles={{root:{borderBottom: 0}}} {...props} />
              </RenderDropStats>
          </Stack>);
  }
  return null;
};

private toggleDeleteDialog = () => {
  this.setState({deleteDialogOpen: !this.state.deleteDialogOpen})
}

private canCreate = () =>
{
  let canCreate = false
  if(this.props.currentUserLevels)
  {
    if(this.props.currentUserLevels < 3)
    {
        canCreate = true;
    }
  }
  return canCreate
}

public render(){
const { columns, items, editStoreModalOpen, selectedEditItem, newStoreModalOpen, deleteDialogOpen} = this.state;

return(
    <div>
      <Stack horizontal>       
        <TextField label="Filter by name:" onChange={(ev, text) => this._onChangeText(ev, text? text: '')} styles={controlStyles} />
        {this.canCreate() && (<div style={{paddingTop: 25}}>
        <CommandBarButton style={{height: 40}} iconProps={{iconName: "Add"}} onClick={() => this.dismissNewStoreModal()} text="New Store"/>
        </div>)}
      </Stack>
      <DetailsList
      items={items}
      // compact={isCompactMode}
      onRenderRow={this._onRenderRow}
      columns={columns}
      selectionMode={SelectionMode.none}
      // getKey={this._getKey}
      setKey="none"
      layoutMode={DetailsListLayoutMode.justified}
      isHeaderVisible={true}
      // onItemInvoked={this._onItemInvoked}
      />
      <NewDocumentStoreModal updateDocumentStoreList={this.props.updateDocumentStoreList} isOpen={newStoreModalOpen} dismissModal={this.dismissNewStoreModal} customerId={this.props.selectedCustomerId}/>
      <Dialog
      hidden={!deleteDialogOpen}
      onDismiss={this.toggleDeleteDialog}
      dialogContentProps={deleteDialogContentProps}
      >
        <DialogFooter>
          <PrimaryButton onClick={this.deleteDocumentStore} text="Ok" />
          <PrimaryButton onClick={this.toggleDeleteDialog} text="Cancel" />
        </DialogFooter>
      </Dialog>
    </div>
)
}

private _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
  this.setState({
    items: text ? this.state.allItems.filter(i => i.name.toLowerCase().indexOf(text.toLowerCase()) > -1) : this.state.allItems,
  });
};

private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
  const { columns, items } = this.state;
  const newColumns: IColumn[] = columns.slice();
  const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
  newColumns.forEach((newCol: IColumn) => {
    if (newCol === currColumn) {
      currColumn.isSortedDescending = !currColumn.isSortedDescending;
      currColumn.isSorted = true;
    } else {
      newCol.isSorted = false;
      newCol.isSortedDescending = true;
    }
  });
  const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
  this.setState({
    columns: newColumns,
    items: newItems,
  });
};

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}



const deleteDialogContentProps = {
  type: DialogType.normal,
  title: 'Delete Document Store',
  subText: 'Are you sure you wish to delete this document store'
}