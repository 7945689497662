
export enum UserPermissionLevels {
    TSGAdmin = 1 << 0,
    TSGSupport = 1 << 1,
    CustomerAdmin = 1 << 2,
    CustomerSupport = 1 << 3
}

/**
    * Represents a Customer Administrator
*/
 export interface CustomerAdministrator {

    /**
        * Specifies the id of the customer administrator.
    */
      id: number;

    /**
        * Specifies the customer id of the customer administrator.
    */
      customerId: number;
    
    /**
        * Specifies the login name of the customer administrator.
    */
      loginName: string;

    /**
        * Specifies if the customer administrator is active.
    */
      isActive: boolean;

    /**
        * Specifies the permissions of the customer administrator.
    */
     level: UserPermissionLevels;
      
  }

  export interface CreateCustomerAdministrator {
    customerId: number;
    id: number;
    isActive: boolean;
    isModified: boolean;
    loginName: string|undefined;
    isPrimary: boolean;
    isInternal: boolean;
    permissions: string[]
  }
  
  
  