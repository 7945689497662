import * as React from 'react';
import QuotaChart from '../components/Dashboard/QuotaChart';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import * as AuthStore from '../store/Auth';
import * as DocumentDetailStore from '../store/DocumentStore';
import { DocumentStoreQuota } from '../interfaces/documentStore';
import { animatedDivClass } from '../styles/defaultStyles';
import { UserPermissionLevels } from '../interfaces/customerAdministrator';


//Set Props to current redux state using ApplicationState/Authstore.
type DashboardProps =
    ApplicationState &
    typeof AuthStore.actionCreators &
    RouteComponentProps<{}>;

class Dashboard extends React.PureComponent<DashboardProps> {

    constructor(props: any) {
        super(props)
    }

    //Render function to loop through documentStoresQuota array and returns QuotaChart components. 
    private renderQuotaChart() {
        if (this.props.documentStore)
            return this.props.documentStore.documentStoresQuota.map((store: DocumentStoreQuota, storeArrayId: number) => {
                return (
                    <div key={storeArrayId} style={{ padding: 10 }}>
                        <QuotaChart customerId={this.props.auth?.SelectedCustomer ? this.props.auth.SelectedCustomer.id : 0} store={store} currentUserLevel={this.props.auth?.CurrentUserLevels ? this.props.auth.CurrentUserLevels : UserPermissionLevels.CustomerSupport} />
                    </div>
                )
            })
    }

    public render() {
        return (
            <React.Fragment>
                <div className={animatedDivClass} style={{ display: 'flex', overflowX: "auto", overflowY: "hidden", height: "42rem", width: "98%", paddingBottom: "10%" }}>
                    {this.renderQuotaChart()}
                </div>
            </React.Fragment>)
    }
};

//Export with connect to redux state
export default connect(
    (state: ApplicationState) => state,
    DocumentDetailStore.actionCreators
)(Dashboard as any);