import * as React from 'react';
import { Modal, PrimaryButton, IButtonStyles, PeoplePickerItemBase, List, Icon, mergeStyleSets, ITheme, getTheme, getFocusStyle, IRectangle } from '@fluentui/react';
import { resubmitDocument } from '../../utilities/axiosUtility';
import { Customer } from '../../interfaces/customer'
import _ from 'lodash';

export interface resubmitDocumentDetails {
  documentId: number;
  storeId: number;
  documentName: string;
}

const RedButtonStyle: IButtonStyles = {
  root: {
    backgroundColor: "#DC1A1A",
    borderColor: "#DC1A1A"
  },
  rootPressed: {
    backgroundColor: "#DC1A1A",
    borderColor: "#DC1A1A"
  },
  rootHovered: {
    backgroundColor: "#CB1818",
    borderColor: "#CB1818"
  }
}

const theme: ITheme = getTheme();
const { palette, semanticColors, fonts } = theme;

const classNames = mergeStyleSets({
  itemCell: [
    getFocusStyle(theme, { inset: -1 }),
    {
      minHeight: 30,
      minWidth: 400,
      padding: 10,
      boxSizing: 'border-box',
      borderBottom: `1px solid ${semanticColors.bodyDivider}`,
      display: 'flex',
      selectors: {
        '&:hover': { background: palette.neutralLight },
      },
    },
  ],
  titleAmount: {
    padding: 10,
  },
  itemImage: {
    flexShrink: 0,
  },
  itemContent: {
    marginLeft: 10,
    overflow: 'hidden',
    flexGrow: 1,
  },
  listContainer: {
    height: 400,
    overflowY: 'auto'
  },
  itemName: [
    fonts.xLarge,
    {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  ],
  itemIndex: {
    fontSize: fonts.small.fontSize,
    color: palette.neutralTertiary,
    marginBottom: 10,
  },
  chevron: {
    float: 'right',
    marginLeft: 'auto',
    fontSize: fonts.large.fontSize,
    flexShrink: 0,
  },
});

const ROWS_PER_PAGE = 10;
const MAX_ROW_HEIGHT = 50;

const DocumentHistoryResubmitModal = (props: { isOpen: boolean, customer: Customer | null, docDetails: resubmitDocumentDetails[], documentResubmitted: any, dismissModal: any }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [documentDetails, setDocumentDetails] = React.useState<resubmitDocumentDetails[]>([])
  const columnCount = React.useRef(0);
  const rowHeight = React.useRef(0);

  React.useEffect(() => {
    setIsOpen(props.isOpen);
    if (props.isOpen === true)
      setDocumentDetails(props.docDetails);
  }, [props.isOpen])

  const _resubmitDocument = () => {
    for (let documentDetail of documentDetails) {
      if (props.customer) {
        resubmitDocument(props.customer.id, documentDetail.storeId, documentDetail.documentId).then(response => {
        }).catch(error => {
          console.log(error)
          alert("Error resubmitted document")
        })
      }
    }
    setTimeout(() => {
      props.dismissModal()
      props.documentResubmitted(props.customer)
    }, 500)
  }

  const _removeDocument = (selectedDocument: number) => {
    let nDocumentDetails = _.clone(documentDetails);
    if (nDocumentDetails.length === 1) {
      props.dismissModal()
    }
    else {
      nDocumentDetails.splice(selectedDocument, 1)
      setDocumentDetails(nDocumentDetails);
    }
  }

  const getItemCountForPage = React.useCallback((itemIndex: number | undefined, surfaceRect: IRectangle | undefined) => {
    if (surfaceRect)
      if (itemIndex === 0) {
        columnCount.current = Math.ceil(surfaceRect.width / MAX_ROW_HEIGHT);
        rowHeight.current = Math.floor(surfaceRect.width / columnCount.current);
      }
    return columnCount.current * ROWS_PER_PAGE;
  }, []);

  const getPageHeight = React.useCallback((): number => {
    return rowHeight.current * ROWS_PER_PAGE;
  }, []);


  const onRenderCell = (item: any, index: number | undefined) => {
    return (
      <div className={classNames.itemCell} data-is-focusable={true}>
        <div>{item.documentName}</div>
        <Icon className={classNames.chevron} style={{ cursor: 'pointer' }} onClick={() => _removeDocument(index ? index : 0)} iconName="RemoveFilter" />
      </div>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={() => props.dismissModal()}
    >
      <div style={{ padding: 20 }}>
        <div>
          <h3 className={classNames.titleAmount}>Resubmitting the document(s) will process {documentDetails.length} document(s) again. would you like to continue? </h3>
          <div className={classNames.listContainer}>
            <List
              items={documentDetails}
              onRenderCell={onRenderCell}
              getItemCountForPage={getItemCountForPage}
              getPageHeight={getPageHeight}
              renderedWindowsAhead={4}
            />
          </div>
        </div>
        <div style={{ display: 'flex', float: 'right', padding: 10 }}>
          <PrimaryButton text="Ok" onClick={() => _resubmitDocument()} />
          <div style={{ padding: 5 }}></div>
          <PrimaryButton styles={RedButtonStyle} text="Cancel" onClick={() => props.dismissModal()} />
        </div>
      </div>
    </Modal>
  )
}

export default DocumentHistoryResubmitModal